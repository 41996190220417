import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import ProductsPage from '../components/scenes/products';
import SEO from '../components/seo';

export const query = graphql`
  {
    allProduct(sort: { order: ASC, fields: name }) {
      nodes {
        id
        slug
        name
        score
        industries
        description
        roasts {
          score
        }
        remoteImage {
          childImageSharp {
            gatsbyImageData(width: 235, placeholder: BLURRED, height: 235)
          }
        }
      }
    }
  }
`;

const Products = ({
  data: {
    allProduct: { nodes },
  },
}) => {
  return (
    <Layout>
      <SEO title="All the Products" />
      <ProductsPage products={nodes} />
    </Layout>
  );
};

export default Products;
