import { Block, Flex, Tag, Text } from '@actovos-consulting-group/ui-core';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { truncate } from 'lodash';
import React from 'react';
import styled, { useTheme } from 'styled-components';

const CardContainer = styled(Block)`
  background: #ffffff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #f3d9ff;
`;

const ProductCard = ({
  product: { name, description, slug, industries = [], score, remoteImage },
}) => {
  const image = getImage(remoteImage);
  const theme = useTheme();

  return (
    <CardContainer mb={40} position="relative">
      <Link
        to={`/product/${slug}`}
        style={{ textDecoration: 'none', color: theme.colors.textColor }}
      >
        {score && (
          <Block
            p={{ _: 1, xs: 2 }}
            bg="primary"
            borderRadius={3}
            position="absolute"
            zIndex={2}
            top={2}
            right={2}
          >
            <Text fontWeight="bold" color="white" fontSize={{ _: 14, xs: 20 }}>
              {score}
            </Text>
          </Block>
        )}
        {image ? (
          <GatsbyImage
            image={image}
            alt={name}
            style={{ borderRadius: 4, width: '100%' }}
            width="100%"
          />
        ) : (
          <img
            alt={name}
            src="/img/placeholder_square.jpg"
            style={{ width: '100%' }}
          />
        )}
        <Flex flexDirection="column" height={{ _: 200, xs: 150, sm: 180 }}>
          <Text mt={3} fontSize={{ _: 13, sm: 18 }} fontWeight="bold">
            {truncate(name, { length: 25 })}
          </Text>
          <Text mt={3} fontSize={{ _: 12, sm: 14 }}>
            {truncate(description, { length: 60 })}
          </Text>
          <Block mt="auto">
            {industries.slice(0, 3).map(tag => (
              <Tag size="sm" key={tag} variant="light">
                {tag}
              </Tag>
            ))}
          </Block>
        </Flex>
      </Link>
    </CardContainer>
  );
};

export default ProductCard;
