import {
  Block,
  Container,
  Grid,
  Loader,
} from '@actovos-consulting-group/ui-core';
import React from 'react';
import ProductCard from './components/product-card';

const ProductsPage = ({ products }) => {
  const loading = false;
  return (
    <Container>
      <Grid.Row gutter={20}>
        {(products || []).map(product => (
          <Grid.Column key={product.id} size={{ _: 12, xxxs: 6, xs: 4, sm: 3 }}>
            <ProductCard product={product} />
          </Grid.Column>
        ))}
      </Grid.Row>
      {!!products.length && (
        <Block height={85} style={{ textAlign: 'center' }}>
          {loading && <Loader size="sm" />}
        </Block>
      )}
    </Container>
  );
};

export default ProductsPage;
